import React from "react";
import Layout from "../components/layout";

const ImpressumPage = () => {
  return (
    <Layout pageTitle="Impressum">
      <div className="flex flex-col space-y-10 md:p-16 p-10 max-w-4xl mx-auto">
        <div>
          <p className="text-2xl mb-3">KONTAKTADRESSE</p>
          <p>Daniela Gisler</p>
          <p>Moosbergstrasse 60</p>
          <p>6284 Gelfingen</p>
        </div>
        <div>
          <p className="text-2xl mb-3">VERTRETUNGSBERECHTIGTE PERSONEN</p>
          <p>Daniela Gisler, Geschäftsführer</p>
        </div>
        <div>
          <p className="text-2xl mb-3">HAFTUNGSAUSSCHLUSS</p>
          <p>
            Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
            Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
            Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor
            wegen Schäden materieller oder immaterieller Art, welche aus dem
            Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten
            Informationen, durch Missbrauch der Verbindung oder durch technische
            Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind
            unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
            Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
            verändern, zu ergänzen, zu löschen oder die Veröffentlichung
            zeitweise oder endgültig einzustellen.
          </p>
        </div>
        <div>
          <p className="text-2xl mb-3">HAFTUNG FÜR LINKS</p>
          <p>
            Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
            Verantwortungsbereichs Es wird jegliche Verantwortung für solche
            Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
            erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
          </p>
        </div>
        <div>
          <p className="text-2xl mb-3">URHEBERRECHTE</p>
          <p>
            Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos
            oder anderen Dateien auf der Website gehören ausschliesslich der
            Firma Dialog oder den speziell genannten Rechtsinhabern. Für die
            Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der
            Urheberrechtsträger im Voraus einzuholen.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ImpressumPage;
